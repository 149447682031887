import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { ListItemIcon } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
export default function Header({ groups }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  console.log("ur a header mate");
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: 1400,
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Link color="text.primary" href="/" sx={{ my: 1, mx: 1.5 }}>
          <Box
            component="img"
            sx={{
              height: 64,
              marginY: 1,
            }}
            alt="East London Community Band Logo"
            src="/elcblogo.png"
          />
        </Link>
        <Typography
          variant="h6"
          color="inherit"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {isMobile ? (
          <DrawerComponent groups={groups} />
        ) : (
          <nav>
            {groups.includes("Admin") ? <AdminMenu /> : null}
            <Link
              variant="button"
              color="text.primary"
              href="/profile"
              sx={{ my: 1, mx: 1.5 }}
            >
              Profile
            </Link>
            <Button href="/signout" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
              Logout
            </Button>
          </nav>
        )}
      </Toolbar>
    </AppBar>
  );
}

function DrawerComponent({ groups }) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  return (
    <>
      <Drawer
        anchor={"top"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem
            variant="button"
            color="text.primary"
            component={Link}
            href="/"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>
          {groups.includes("Admin") ? (
            <>
              <ListItem
                variant="button"
                color="text.primary"
                component={Link}
                onClick={handleOpenSettings}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>

                <ListItemText>Admin </ListItemText>
              </ListItem>
              <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    variant="button"
                    color="text.primary"
                    component={Link}
                    href="/enrolments"
                  >
                    <ListItemText inset primary="Enrolments" />
                  </ListItem>
                  <ListItem
                    variant="button"
                    color="text.primary"
                    component={Link}
                    href="/members"
                  >
                    <ListItemText inset primary="Members" />
                  </ListItem>

                  <ListItem
                    variant="button"
                    color="text.primary"
                    component={Link}
                    href="/bands"
                  >
                    <ListItemText inset primary="Data Setup" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          ) : null}
          <ListItem
            variant="button"
            color="text.primary"
            component={Link}
            href="/profile"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </ListItem>
          <ListItem
            variant="button"
            color="text.primary"
            component={Link}
            href="/signout"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

function AdminMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        variant="button"
        color="text.primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ my: 1, mx: 1.5 }}
      >
        Admin
      </Link>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem component={Link} href="/enrolments" onClick={handleClose}>
          Enrolments
        </MenuItem>
        <MenuItem component={Link} href="/members" onClick={handleClose}>
          Members
        </MenuItem>

        <MenuItem component={Link} href="/bands" onClick={handleClose}>
          Data Setup
        </MenuItem>
      </Menu>
    </>
  );
}
