import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

import ELCBLoading from "./ELCBLoading";
import Header from "./ELCBHeader";
import { Auth } from "aws-amplify";
import moment from "moment";
import { API } from "aws-amplify";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import * as queries from "../graphql/queries";
import { useNavigate, Navigate } from "react-router-dom";
import { createEnrolment as createEnrolmentMutation } from "../graphql/mutations";
const age = (birthdate) => {
  return moment().diff(birthdate, "years");
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="https://www.eastlondoncommunityband.co.uk">
        East London Community Band
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

export default function ELCBAdminLanding() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});
  const [groups, setGroups] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [members, setMembers] = React.useState(false);

  const getMembers = `query ListMembers {
    users{
      Username
      Attributes {
        Name 
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }`;

  React.useEffect(() => {
    const fetchedMembers = API.graphql({
      query: getMembers,
    });
    const fetchedUserDetails = Auth.currentAuthenticatedUser();
    const fetchSession = Auth.currentSession();
    const fontLoaded = document.fonts.load("12px 'Josefin Sans'");

    Promise.all([
      fetchedMembers,
      fetchedUserDetails,
      fetchSession,
      fontLoaded,
    ]).then((values) => {
      const user = values[1];
      setUser(user);

      const members = values[0];

      setMembers(members.data.users);
      console.log("Got enrolments");

      const session = values[2];
      setGroups(session.getIdToken().payload["cognito:groups"] || []);

      setIsLoaded(true);
      console.log("shoudl have loaded");
      console.log(values);
    });
  }, []);

  return isLoaded ? (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Header groups={groups} />

      <MembersGrid members={members} />

      <Footer />
    </>
  ) : (
    <ELCBLoading />
  );
}

function Footer() {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [1, 1],
      }}
    >
      <Copyright sx={{ mt: 0 }} />
    </Container>
  );
}

function Hero({ user }) {
  return (
    <Container
      // disableGutters
      maxWidth="sm"
      component="main"
      sx={{ pt: 8, pb: 6 }}
    >
      <Typography
        component="h1"
        variant="h3"
        align="left"
        color="text.primary"
        gutterBottom
      >
        {user.attributes.name}, Welcome to the Admin Portal.
      </Typography>
      <Typography
        variant="h5"
        align="left"
        color="text.secondary"
        component="p"
      >
        “I play the orchestra. And you’re a good musician.” Steve Jobs
      </Typography>
    </Container>
  );
}

function MembersGrid({ members }) {
  // Username
  // Attributes
  // UserCreateDate
  // UserLastModifiedDate
  // Enabled
  // UserStatus
  // MFAOptions
  // PreferredMfaSetting
  // UserMFASettingList

  const columns = [
    {
      field: "Username",
      headerName: "Username",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      valueGetter: (params) => {
        return (
          (params.row.Attributes?.filter((b) => {
            return b.Name == "name";
          })[0]?.Value || "") +
          " " +
          (params.row.Attributes?.filter((b) => {
            return b.Name == "family_name";
          })[0]?.Value || "")
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      width: 230,
      valueGetter: (params) => {
        return (
          params.row.Attributes?.filter((b) => {
            return b.Name == "email";
          })[0]?.Value || ""
        );
      },
    },
    {
      field: "dateofbirth",
      headerName: "Date Of Birth",
      width: 100,
      valueGetter: (params) => {
        return (
          params.row.Attributes?.filter((b) => {
            return b.Name == "birthdate";
          })[0]?.Value || ""
        );
      },
    },

    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      valueGetter: (params) => {
        return (
          params.row.Attributes?.filter((b) => {
            return b.Name == "gender";
          })[0]?.Value || ""
        );
      },
    },
    {
      field: "ethncitiy",
      headerName: "Ethnciity",
      width: 100,
      valueGetter: (params) => {
        return (
          params.row.Attributes?.filter((b) => {
            return b.Name == "custom:ethnicity";
          })[0]?.Value || ""
        );
      },
    },
    {
      field: "sibling",
      headerName: "Has Siblings?",
      width: 100,
      valueGetter: (params) => {
        return params.row.Attributes?.filter((b) => {
          return b.Name == "profile";
        })[0]?.Value === "siblings"
          ? true
          : false;
      },
    },
    {
      field: "UserCreateDate",
      headerName: "Date Created",
      width: 180,
    },
    {
      field: "UserLastModifiedDate",
      headerName: "Last Updated",
      width: 180,
    },
    {
      field: "Enabled",
      headerName: "Enabled",
      width: 100,
    },
    {
      field: "UserStatus",
      headerName: "Signup Status",
      width: 150,
    },
  ];

  const rows = members;
  console.log(members);
  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        marginY={1}
        paddingX={{
          xs: "10px",
          sm: "100px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
        >
          Members
        </Typography>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.Username}
            components={{ Toolbar: GridToolbar }}
            key={Math.random()}
            title="Enrolments"
            rows={rows}
            columns={columns}
            pageSize={2}
            rowsPerPageOptions={[2]}
            disableSelectionOnClick
          />
        </div>
      </Grid>
    </>
  );
}

function LoadingButton({ session, tier }) {
  const [isLoading, setIsloading] = React.useState(false);
  return (
    <Button
      disabled={isLoading}
      onClick={() => {
        setIsloading(true);
        // create enrolment
        API.graphql({
          query: createEnrolmentMutation,
          variables: {
            input: { bands: tier.bands, lessons: tier.lessons },
          },
        }).then((res) => {
          API.post("checkout", "/checkout", {
            body: {
              accesskey: session.accessToken,
              enrolmentId: res.data.createEnrolment.id,
            },
          }).then((res) => {
            window.location.replace(res.url);
          });
        });
      }}
      fullWidth
      variant={tier.buttonVariant}
    >
      {isLoading ? (
        <>
          <CircularProgress size={12} /> &nbsp;
        </>
      ) : null}
      {tier.buttonText}
    </Button>
  );
}
