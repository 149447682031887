import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/StarBorder";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
  randomId,
} from "@mui/x-data-grid-generator";
import ELCBLoading from "./ELCBLoading";
import Header from "./ELCBHeader";
import { Auth } from "aws-amplify";
import moment from "moment";
import { API } from "aws-amplify";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GRID_DATE_COL_DEF,
  useGridApiContext,
} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import * as queries from "../graphql/queries";
import { useNavigate, Navigate } from "react-router-dom";
import { createEnrolment as createEnrolmentMutation } from "../graphql/mutations";
const age = (birthdate) => {
  return moment().diff(birthdate, "years");
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="https://www.eastlondoncommunityband.co.uk">
        East London Community Band
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

export default function ELCBAdminLanding() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});
  const [groups, setGroups] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [members, setMembers] = React.useState(false);

  React.useEffect(() => {
    const fetchedUserDetails = Auth.currentAuthenticatedUser();
    const fetchSession = Auth.currentSession();
    const fontLoaded = document.fonts.load("12px 'Josefin Sans'");

    Promise.all([fetchedUserDetails, fetchSession, fontLoaded]).then(
      (values) => {
        const user = values[0];
        setUser(user);

        const session = values[1];
        setGroups(session.getIdToken().payload["cognito:groups"] || []);

        setIsLoaded(true);
      }
    );
  }, []);

  return isLoaded ? (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Header groups={groups} />

      <TermsGrid members={members} />
      <BandsGrid members={members} />

      <Footer />
    </>
  ) : (
    <ELCBLoading />
  );
}

function Footer() {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [1, 1],
      }}
    >
      <Copyright sx={{ mt: 0 }} />
    </Container>
  );
}

function Hero({ user }) {
  return (
    <Container
      // disableGutters
      maxWidth="sm"
      component="main"
      sx={{ pt: 8, pb: 6 }}
    >
      <Typography
        component="h1"
        variant="h3"
        align="left"
        color="text.primary"
        gutterBottom
      >
        {user.attributes.name}, Welcome to the Admin Portal.
      </Typography>
      <Typography
        variant="h5"
        align="left"
        color="text.secondary"
        component="p"
      >
        “I play the orchestra. And you’re a good musician.” Steve Jobs
      </Typography>
    </Container>
  );
}

function TermsGrid({ members }) {
  const dateColumnType = {
    ...GRID_DATE_COL_DEF,
    renderEditCell: (params) => <GridEditDateCell {...params} />,
  };
  function GridEditDateCell({ id, field, value }) {
    const apiRef = useGridApiContext();

    function handleChange(newValue) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }

    return (
      <DatePicker
        value={value}
        renderInput={(params) => <TextField {...params} />}
        onChange={handleChange}
        inputFormat="DD/MM/yyyy"
      />
    );
  }

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const createTerm = /* GraphQL */ `
      mutation CreateTerm(
        $input: CreateTermInput!
        $condition: ModelTermConditionInput
      ) {
        createTerm(input: $input, condition: $condition) {
          id
          description
          startDate
          endDate
          bandpackages {
            nextToken
          }
          lessonpackage {
            nextToken
          }
          createdAt
          updatedAt
        }
      }
    `;
    const handleClick = () => {
      // create new thingy
      API.graphql({
        query: createTerm,
        variables: {
          input: {
            description: "20XX [Winter/Spring/Summer] Term",
          },
        },
      }).then((res) => {
        console.log(res);
        const id = res.data.createTerm.id;
        setRows((oldRows) => [
          ...oldRows,
          { ...res.data.createTerm, isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: "description" },
        }));
      });
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add term
        </Button>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };
  const list = /* GraphQL */ `
    query ListTerms(
      $filter: ModelTermFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          description
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

  React.useEffect(() => {
    const fetchedItems = API.graphql({
      query: list,
    });

    Promise.all([fetchedItems]).then((values) => {
      setRows(values[0].data.listTerms.items);
    });
  }, []);

  //---

  const [rows, setRows] = React.useState([]);

  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    console.log("handleSaveClick - id is " + id);
    console.log("save" + JSON.stringify(rows.filter((row) => row.id === id)));
    //processRowUpdate()
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    // delete
    const deleteTerm = /* GraphQL */ `
      mutation DeleteTerm(
        $input: DeleteTermInput!
        $condition: ModelTermConditionInput
      ) {
        deleteTerm(input: $input, condition: $condition) {
          id
          description
          startDate
          endDate
          bandpackages {
            nextToken
          }
          lessonpackage {
            nextToken
          }
          createdAt
          updatedAt
        }
      }
    `;
    API.graphql({
      query: deleteTerm,
      variables: {
        input: { id: id },
      },
    }).then((res) => {
      console.log(res);
      setRows(rows.filter((row) => row.id !== id));
    });
  };

  const processRowUpdateError = (e) => {
    console.log("there was an error there");
    console.log(e);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log("does error happen here?");
    const updateTerm = /* GraphQL */ `
      mutation UpdateTerm(
        $input: UpdateTermInput!
        $condition: ModelTermConditionInput
      ) {
        updateTerm(input: $input, condition: $condition) {
          id
          description
          startDate
          endDate
          bandpackages {
            nextToken
          }
          lessonpackage {
            nextToken
          }
          createdAt
          updatedAt
        }
      }
    `;
    let newTerm = { ...newRow };
    delete newTerm.isNew;
    delete newTerm.createdAt;
    delete newTerm.updatedAt;

    API.graphql({
      query: updateTerm,
      variables: {
        input: newTerm,
      },
    }).then((res) => {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      width: 300,
      editable: true,
    },

    {
      field: "startDate",
      ...dateColumnType,
      headerName: "Start Date",
      width: 180,
      valueGetter: (params) => moment(params.row.startDate).toDate(),
      editable: true,
    },

    {
      field: "endDate",
      ...dateColumnType,
      headerName: "End Date",
      width: 180,
      valueGetter: (params) => moment(params.row.endDate).toDate(),
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        marginY={1}
        paddingX={{
          xs: "10px",
          sm: "100px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
        >
          Terms
        </Typography>
        <Typography
          variant="body"
          align="left"
          color="text.secondary"
          component="p"
        >
          Here's where you setup the terms, which people sign up to. <br />
          To edit a term's information click <EditIcon /> in the Actions menu.
          To delete it, click <DeleteIcon />.
        </Typography>
        <div style={{ height: 300, width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DataGrid
              experimentalFeatures={{ newEditingApi: true }}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              title="Enrolments"
              rows={rows}
              columns={columns}
              pageSize={2}
              rowsPerPageOptions={[2]}
              disableSelectionOnClick
            />
          </LocalizationProvider>
        </div>
      </Grid>
    </>
  );
}

function BandsGrid({ members }) {
  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const createBand = /* GraphQL */ `
      mutation CreateBand(
        $input: CreateBandInput!
        $condition: ModelBandConditionInput
      ) {
        createBand(input: $input, condition: $condition) {
          id
          description
          package {
            nextToken
          }
          active
          createdAt
          updatedAt
        }
      }
    `;
    const handleClick = () => {
      // create new thingy
      API.graphql({
        query: createBand,
        variables: {
          input: {
            description: "Enter new band description",
            active: false,
          },
        },
      }).then((res) => {
        console.log(res);
        const id = res.data.createBand.id;
        setRows((oldRows) => [
          ...oldRows,
          { ...res.data.createBand, isNew: true },
        ]);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: "description" },
        }));
      });
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add band
        </Button>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };

  const list = /* GraphQL */ `
    query ListBands(
      $filter: ModelBandFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listBands(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          active
          description
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

  React.useEffect(() => {
    const fetchedItems = API.graphql({
      query: list,
    });

    Promise.all([fetchedItems]).then((values) => {
      setRows(values[0].data.listBands.items);
    });
  }, []);

  //---

  const [rows, setRows] = React.useState([]);

  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    console.log("handleSaveClick - id is " + id);
    console.log("save" + JSON.stringify(rows.filter((row) => row.id === id)));
    //processRowUpdate()
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    // delete
    const deleteBand = /* GraphQL */ `
      mutation DeleteBand(
        $input: DeleteBandInput!
        $condition: ModelBandConditionInput
      ) {
        deleteBand(input: $input, condition: $condition) {
          id
          description
          package {
            nextToken
          }
          active
          createdAt
          updatedAt
        }
      }
    `;
    API.graphql({
      query: deleteBand,
      variables: {
        input: { id: id },
      },
    }).then((res) => {
      console.log(res);
      setRows(rows.filter((row) => row.id !== id));
    });
  };

  const processRowUpdateError = (e) => {
    console.log("there was an error there");
    console.log(e);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log("does error happen here?");

    const updateBand = /* GraphQL */ `
      mutation UpdateBand(
        $input: UpdateBandInput!
        $condition: ModelBandConditionInput
      ) {
        updateBand(input: $input, condition: $condition) {
          id
          description
          package {
            nextToken
          }
          active
          createdAt
          updatedAt
        }
      }
    `;
    let newBand = { ...newRow };
    delete newBand.isNew;
    delete newBand.createdAt;
    delete newBand.updatedAt;

    API.graphql({
      query: updateBand,
      variables: {
        input: newBand,
      },
    }).then((res) => {
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      width: 200,
      editable: true,
    },

    {
      field: "active",
      headerName: "active",
      width: 70,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        marginY={1}
        paddingX={{
          xs: "10px",
          sm: "100px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
        >
          Bands
        </Typography>
        <Typography
          variant="body"
          align="left"
          color="text.secondary"
          component="p"
        >
          Here's where you setup the individual bands, which form part of the
          various band packages members can sign up for. This also drives
          'band-membership' reporting. <br />
          To edit a band's information click <EditIcon /> in the Actions menu.
          To delete it, click <DeleteIcon />.
        </Typography>
        <div style={{ height: 300, width: "100%" }}>
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={processRowUpdateError}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            title="Enrolments"
            rows={rows}
            columns={columns}
            pageSize={2}
            rowsPerPageOptions={[2]}
            disableSelectionOnClick
          />
        </div>
      </Grid>
    </>
  );
}
