import * as React from "react";
import MaterialReactTable from 'material-react-table';
import PendingIcon from '@mui/icons-material/Pending';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PaidIcon from '@mui/icons-material/Paid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

import ELCBLoading from "./ELCBLoading";
import Header from "./ELCBHeader";
import { Auth } from "aws-amplify";
import moment from "moment";
import { API } from "aws-amplify";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import * as queries from "../graphql/queries";
import { useNavigate, Navigate } from "react-router-dom";
import { createEnrolment as createEnrolmentMutation } from "../graphql/mutations";
const age = (birthdate) => {
  return moment().diff(birthdate, "years");
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="https://www.eastlondoncommunityband.co.uk">
        East London Community Band
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
  },
  {
    title: "Features",
    description: [
      "Cool stuff",
      "Random feature",
      "Team feature",
      "Developer stuff",
      "Another one",
    ],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

export default function ELCBAdminLanding() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});
  const [groups, setGroups] = React.useState([]);
  const [session, setSession] = React.useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isEnrolled, setIsEnrolled] = React.useState(false);
  const [enrolments, setEnrolments] = React.useState(false);

  const getEnrolments = `query ListEnrolments(
    $filter: ModelEnrolmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnrolments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bands
        status
        term
        bandMembershipType
        bandDesc
        bandRate
        lessons
        lessonDesc
        lessonRate
        stripeRef
        total
        giftAidConsent
        city
        line1
        instrumentsPlayed {
          Band 
          Instrument}
        line2
        postCode
        email
        siblings
        firstname
        familyname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }`;

  React.useEffect(() => {
    const fetchedEnrolments = API.graphql({
      query: getEnrolments,
    });
    const fetchedUserDetails = Auth.currentAuthenticatedUser();
    const fetchSession = Auth.currentSession();
    const fontLoaded = document.fonts.load("12px 'Josefin Sans'");

    Promise.all([
      fetchedEnrolments,
      fetchedUserDetails,
      fetchSession,
      fontLoaded,
    ]).then((values) => {
      const user = values[1];
      setUser(user);

      const enrolments = values[0];

      setEnrolments(enrolments.data.listEnrolments.items);
      console.log("Got enrolments");

      const session = values[2];
      setSession(session);
      setGroups(session.getIdToken().payload["cognito:groups"] || []);

      setIsLoaded(true);
      console.log("shoudl have loaded");
      console.log(values);
    });
  }, []);

  return isLoaded ? (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Header groups={groups} />

      <ReactTableMembershipGrid enrolments={enrolments} />
      <MembershipGrid enrolments={enrolments} />

      <Footer />
    </>
  ) : (
    <ELCBLoading />
  );
}

function Footer() {
  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [1, 1],
      }}
    >
      <Copyright sx={{ mt: 0 }} />
    </Container>
  );
}


function MembershipGrid({ enrolments }) {
  const columns = [
    { field: "status", headerName: "Status", columnResizable: true, width: 100 },
    {
      field: "total",
      headerName: "Total fees (£)",
      width: 100,
      valueFormatter: (params) => "£" + Number(params.value).toFixed(2),
      columnResizable: true,
    },
    {
      field: "firstname",
      headerName: "First name",
      width: 100,
      columnResizable: true,
    },
    {
      field: "familyname",
      headerName: "Family name",
      width: 100,
      columnResizable: true,
    },

    { field: "email", headerName: "Email", width: 300 },

    { field: "line1", headerName: "Address Line 1", width: 150 },
    { field: "line2", headerName: "Address Line 2", width: 150 },
    { field: "city", headerName: "City", width: 100 },
    { field: "postCode", headerName: "Post Code", width: 100 },

    { field: "stripeRef", headerName: "Stripe", columnResizable: true, width: 250 },
    { field: "term", headerName: "Term", columnResizable: true, width: 200 },
    {
      field: "bandDesc",
      headerName: "Band Membership Level",
      width: 300,
      columnResizable: true,
    },
    {
      field: "bandRate",
      headerName: "Band Rate Paid (£)",
      width: 200,
      valueFormatter: (params) => "£" + Number(params.value).toFixed(2),
      columnResizable: true,
    },
    { field: "lessonDesc", headerName: "Lessons", width: 200, columnResizable: true },
    {
      field: "lessonRate",
      headerName: "Lesson rate paid (£)",
      width: 200,
      valueFormatter: (params) => "£" + Number(params.value).toFixed(2),
      columnResizable: true,
    },

    { field: "giftAidConsent", headerName: "Gift Aid Consent", width: 150 },
  ];
  const bands = [
    "Main Band",
    "Big Band",
    "Percussion",
    "Early Music",
    "Premier Band",
    "Jazz Stompers",
    "Chamber Band",
    "Jazz Combo",
  ];

  columns.push(
    ...bands.map((band) => {
      return {
        field: band,
        headerName: band,
        width: 150,
        valueGetter: (params) => {
          return (
            params.row.instrumentsPlayed?.filter((b) => {
              return b.Band == band;
            })[0]?.Instrument || ""
          );
        },
      };
    })
  );
  console.log(columns);

  const rows = enrolments;
  console.log(enrolments);
  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        marginY={1}
        paddingX={{
          xs: "10px",
          sm: "100px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
        >
          Enrolments
        </Typography>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            key={Math.random()}
            title="Enrolments"
            rows={rows}
            columns={columns}
            pageSize={2}
            rowsPerPageOptions={[2]}
            disableSelectionOnClick
          />
        </div>
      </Grid>
    </>
  );
}

function ReactTableMembershipGrid({ enrolments }) {

  const columns = React.useMemo(
    () => [

      {
        accessorKey: 'status', //access nested data with dot notation
        header: 'Status',
        Cell: ({ renderedCellValue, row }) => {
          let icon = ''
          if (renderedCellValue == 'paid') {
            icon = <> <PaidIcon color="green" /> - Paid </>
          }
          if (renderedCellValue == 'pending') {
            icon = <><PendingIcon /> - Pending</>
          }
          return (

            <>   {icon}</>


          )

        },
      },
      {
        accessorKey: 'total',
        header: 'Total Fees (£)',
      },
      {
        accessorKey: 'firstname', //normal accessorKey
        header: 'First Name',
      },


      {
        accessorKey: "familyname",
        header: "Family name",
        size: 100,
        columnResizable: true,
      },

      { accessorKey: "email", header: "Email", size: 300 },

      { accessorKey: "line1", header: "Address Line 1", size: 150 },
      { accessorKey: "line2", header: "Address Line 2", size: 150 },
      { accessorKey: "city", header: "City", size: 100 },
      { accessorKey: "postCode", header: "Post Code", size: 100 },

      { accessorKey: "stripeRef", header: "Stripe", columnResizable: true, size: 250 },
      { accessorKey: "term", header: "Term", columnResizable: true, size: 200 },
      {
        accessorKey: "bandDesc",
        header: "Band Membership Level",
        size: 300,
        columnResizable: true,
      },
      {
        accessorKey: "bandRate",
        header: "Band Rate Paid (£)",
        size: 200,
        accessorFn: (row) => "£" + Number(row.bandRate).toFixed(2),
        columnResizable: true,
      },
      { accessorKey: "lessonDesc", header: "Lessons", size: 200, columnResizable: true },
      {
        accessorKey: "lessonRate",
        header: "Lesson rate paid (£)",
        size: 200,
        valueFormatter: (params) => "£" + Number(params.value).toFixed(2),
        columnResizable: true,
      },

      {
        accessorKey: "giftAidConsent", header: "Gift Aid Consent", size: 150,
        accessorFn: (row) => row.giftAidConsent ? 'Yes' : 'No'
      },
    ],
    [],
  );

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(csvOptions);


  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
    //console.log(rows)
  };
  const rows = enrolments;
  console.log(enrolments);
  return (
    <>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        spacing={0}
        marginY={1}
        paddingX={{
          xs: "10px",
          sm: "100px",
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="text.secondary"
          component="p"
        >
          Enrolments
        </Typography>
        <div style={{ height: 600, width: "100%" }}>
          <MaterialReactTable
            enableColumnOrdering
            enableGrouping
            enableRowSelection
            enableColumnResizing
            enablePinning
            initialState={{ grouping: ['term', 'status'], expanded: true }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >

                <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportRows(table.getPrePaginationRowModel().rows)
                  }
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  Export All Rows
                </Button>
                <Button
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  //only export selected rows
                  onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                >
                  Export Selected Rows
                </Button>
              </Box>
            )}
            columns={columns} data={rows} />
        </div>
      </Grid>
    </>
  );
}

function LoadingButton({ session, tier }) {
  const [isLoading, setIsloading] = React.useState(false);
  return (
    <Button
      disabled={isLoading}
      onClick={() => {
        setIsloading(true);
        // create enrolment
        API.graphql({
          query: createEnrolmentMutation,
          variables: {
            input: { bands: tier.bands, lessons: tier.lessons },
          },
        }).then((res) => {
          API.post("checkout", "/checkout", {
            body: {
              accesskey: session.accessToken,
              enrolmentId: res.data.createEnrolment.id,
            },
          }).then((res) => {
            window.location.replace(res.url);
          });
        });
      }}
      fullWidth
      variant={tier.buttonVariant}
    >
      {isLoading ? (
        <>
          <CircularProgress size={12} /> &nbsp;
        </>
      ) : null}
      {tier.buttonText}
    </Button>
  );
}
