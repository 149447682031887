/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const users = /* GraphQL */ `
  query Users {
    users {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
      }
      PreferredMfaSetting
      UserMFASettingList
    }
  }
`;
export const getEnrolment = /* GraphQL */ `
  query GetEnrolment($id: ID!) {
    getEnrolment(id: $id) {
      id
      bands
      status
      term
      bandMembershipType
      bandDesc
      bandRate
      lessons
      lessonDesc
      lessonRate
      stripeRef
      total
      giftAidConsent
      city
      line1
      line2
      postCode
      email
      siblings
      firstname
      familyname
      instrumentsPlayed {
        Band
        Instrument
      }
      member {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        PreferredMfaSetting
        UserMFASettingList
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEnrolments = /* GraphQL */ `
  query ListEnrolments(
    $filter: ModelEnrolmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnrolments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bands
        status
        term
        bandMembershipType
        bandDesc
        bandRate
        lessons
        lessonDesc
        lessonRate
        stripeRef
        total
        giftAidConsent
        city
        line1
        line2
        postCode
        email
        siblings
        firstname
        familyname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTerm = /* GraphQL */ `
  query GetTerm($id: ID!) {
    getTerm(id: $id) {
      id
      description
      startDate
      endDate
      bandpackages {
        nextToken
      }
      lessonpackage {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTerms = /* GraphQL */ `
  query ListTerms(
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBandPackage = /* GraphQL */ `
  query GetBandPackage($id: ID!) {
    getBandPackage(id: $id) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      bands {
        nextToken
      }
      createdAt
      updatedAt
      termBandpackagesId
    }
  }
`;
export const listBandPackages = /* GraphQL */ `
  query ListBandPackages(
    $filter: ModelBandPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBandPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        createdAt
        updatedAt
        termBandpackagesId
      }
      nextToken
    }
  }
`;
export const getBand = /* GraphQL */ `
  query GetBand($id: ID!) {
    getBand(id: $id) {
      id
      description
      package {
        nextToken
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const listBands = /* GraphQL */ `
  query ListBands(
    $filter: ModelBandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLessonPackage = /* GraphQL */ `
  query GetLessonPackage($id: ID!) {
    getLessonPackage(id: $id) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      createdAt
      updatedAt
      termLessonpackageId
    }
  }
`;
export const listLessonPackages = /* GraphQL */ `
  query ListLessonPackages(
    $filter: ModelLessonPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        createdAt
        updatedAt
        termLessonpackageId
      }
      nextToken
    }
  }
`;
export const getPackageBands = /* GraphQL */ `
  query GetPackageBands($id: ID!) {
    getPackageBands(id: $id) {
      id
      bandPackageId
      bandId
      bandPackage {
        id
        description
        createdAt
        updatedAt
        termBandpackagesId
      }
      band {
        id
        description
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPackageBands = /* GraphQL */ `
  query ListPackageBands(
    $filter: ModelPackageBandsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackageBands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bandPackageId
        bandId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const packageBandsByBandPackageId = /* GraphQL */ `
  query PackageBandsByBandPackageId(
    $bandPackageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPackageBandsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packageBandsByBandPackageId(
      bandPackageId: $bandPackageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandPackageId
        bandId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const packageBandsByBandId = /* GraphQL */ `
  query PackageBandsByBandId(
    $bandId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPackageBandsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packageBandsByBandId(
      bandId: $bandId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bandPackageId
        bandId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
