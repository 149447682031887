/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEnrolment = /* GraphQL */ `
  mutation CreateEnrolment(
    $input: CreateEnrolmentInput!
    $condition: ModelEnrolmentConditionInput
  ) {
    createEnrolment(input: $input, condition: $condition) {
      id
      bands
      status
      term
      bandMembershipType
      bandDesc
      bandRate
      lessons
      lessonDesc
      lessonRate
      stripeRef
      total
      giftAidConsent
      city
      line1
      line2
      postCode
      email
      siblings
      firstname
      familyname
      instrumentsPlayed {
        Band
        Instrument
      }
      member {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        PreferredMfaSetting
        UserMFASettingList
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEnrolment = /* GraphQL */ `
  mutation UpdateEnrolment(
    $input: UpdateEnrolmentInput!
    $condition: ModelEnrolmentConditionInput
  ) {
    updateEnrolment(input: $input, condition: $condition) {
      id
      bands
      status
      term
      bandMembershipType
      bandDesc
      bandRate
      lessons
      lessonDesc
      lessonRate
      stripeRef
      total
      giftAidConsent
      city
      line1
      line2
      postCode
      email
      siblings
      firstname
      familyname
      instrumentsPlayed {
        Band
        Instrument
      }
      member {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        PreferredMfaSetting
        UserMFASettingList
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEnrolment = /* GraphQL */ `
  mutation DeleteEnrolment(
    $input: DeleteEnrolmentInput!
    $condition: ModelEnrolmentConditionInput
  ) {
    deleteEnrolment(input: $input, condition: $condition) {
      id
      bands
      status
      term
      bandMembershipType
      bandDesc
      bandRate
      lessons
      lessonDesc
      lessonRate
      stripeRef
      total
      giftAidConsent
      city
      line1
      line2
      postCode
      email
      siblings
      firstname
      familyname
      instrumentsPlayed {
        Band
        Instrument
      }
      member {
        Username
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        PreferredMfaSetting
        UserMFASettingList
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTerm = /* GraphQL */ `
  mutation CreateTerm(
    $input: CreateTermInput!
    $condition: ModelTermConditionInput
  ) {
    createTerm(input: $input, condition: $condition) {
      id
      description
      startDate
      endDate
      bandpackages {
        nextToken
      }
      lessonpackage {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTerm = /* GraphQL */ `
  mutation UpdateTerm(
    $input: UpdateTermInput!
    $condition: ModelTermConditionInput
  ) {
    updateTerm(input: $input, condition: $condition) {
      id
      description
      startDate
      endDate
      bandpackages {
        nextToken
      }
      lessonpackage {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTerm = /* GraphQL */ `
  mutation DeleteTerm(
    $input: DeleteTermInput!
    $condition: ModelTermConditionInput
  ) {
    deleteTerm(input: $input, condition: $condition) {
      id
      description
      startDate
      endDate
      bandpackages {
        nextToken
      }
      lessonpackage {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBandPackage = /* GraphQL */ `
  mutation CreateBandPackage(
    $input: CreateBandPackageInput!
    $condition: ModelBandPackageConditionInput
  ) {
    createBandPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      bands {
        nextToken
      }
      createdAt
      updatedAt
      termBandpackagesId
    }
  }
`;
export const updateBandPackage = /* GraphQL */ `
  mutation UpdateBandPackage(
    $input: UpdateBandPackageInput!
    $condition: ModelBandPackageConditionInput
  ) {
    updateBandPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      bands {
        nextToken
      }
      createdAt
      updatedAt
      termBandpackagesId
    }
  }
`;
export const deleteBandPackage = /* GraphQL */ `
  mutation DeleteBandPackage(
    $input: DeleteBandPackageInput!
    $condition: ModelBandPackageConditionInput
  ) {
    deleteBandPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      bands {
        nextToken
      }
      createdAt
      updatedAt
      termBandpackagesId
    }
  }
`;
export const createBand = /* GraphQL */ `
  mutation CreateBand(
    $input: CreateBandInput!
    $condition: ModelBandConditionInput
  ) {
    createBand(input: $input, condition: $condition) {
      id
      description
      package {
        nextToken
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateBand = /* GraphQL */ `
  mutation UpdateBand(
    $input: UpdateBandInput!
    $condition: ModelBandConditionInput
  ) {
    updateBand(input: $input, condition: $condition) {
      id
      description
      package {
        nextToken
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteBand = /* GraphQL */ `
  mutation DeleteBand(
    $input: DeleteBandInput!
    $condition: ModelBandConditionInput
  ) {
    deleteBand(input: $input, condition: $condition) {
      id
      description
      package {
        nextToken
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const createLessonPackage = /* GraphQL */ `
  mutation CreateLessonPackage(
    $input: CreateLessonPackageInput!
    $condition: ModelLessonPackageConditionInput
  ) {
    createLessonPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      createdAt
      updatedAt
      termLessonpackageId
    }
  }
`;
export const updateLessonPackage = /* GraphQL */ `
  mutation UpdateLessonPackage(
    $input: UpdateLessonPackageInput!
    $condition: ModelLessonPackageConditionInput
  ) {
    updateLessonPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      createdAt
      updatedAt
      termLessonpackageId
    }
  }
`;
export const deleteLessonPackage = /* GraphQL */ `
  mutation DeleteLessonPackage(
    $input: DeleteLessonPackageInput!
    $condition: ModelLessonPackageConditionInput
  ) {
    deleteLessonPackage(input: $input, condition: $condition) {
      id
      description
      tariffs {
        applicableTo
        price
      }
      createdAt
      updatedAt
      termLessonpackageId
    }
  }
`;
export const createPackageBands = /* GraphQL */ `
  mutation CreatePackageBands(
    $input: CreatePackageBandsInput!
    $condition: ModelPackageBandsConditionInput
  ) {
    createPackageBands(input: $input, condition: $condition) {
      id
      bandPackageId
      bandId
      bandPackage {
        id
        description
        createdAt
        updatedAt
        termBandpackagesId
      }
      band {
        id
        description
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePackageBands = /* GraphQL */ `
  mutation UpdatePackageBands(
    $input: UpdatePackageBandsInput!
    $condition: ModelPackageBandsConditionInput
  ) {
    updatePackageBands(input: $input, condition: $condition) {
      id
      bandPackageId
      bandId
      bandPackage {
        id
        description
        createdAt
        updatedAt
        termBandpackagesId
      }
      band {
        id
        description
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePackageBands = /* GraphQL */ `
  mutation DeletePackageBands(
    $input: DeletePackageBandsInput!
    $condition: ModelPackageBandsConditionInput
  ) {
    deletePackageBands(input: $input, condition: $condition) {
      id
      bandPackageId
      bandId
      bandPackage {
        id
        description
        createdAt
        updatedAt
        termBandpackagesId
      }
      band {
        id
        description
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
